import { useCallback, useEffect, useMemo, useState } from 'react';

import {
  Control,
  useWatch,
  Controller,
  FieldError,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';

import { Button } from 'ui';
import TextField from 'ui/form/TextField';
import CheckboxField from 'ui/form/CheckboxField';
import DatePickerFieldDobleView, {
  RangeDate,
} from 'ui/form/DatePickerFieldDobleView';

import omit from 'lodash/omit';

import { MaskInterface } from 'utils/masks';

import { ChargeStatus } from 'charges/models/charges';

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

export type FormFilters = {
  chargeDate: string;
  chargePaymentDate?: RangeDate | null;
  chargeCreationDate?: RangeDate | null;
  chargeExpirationDate?: RangeDate | null;
  chargePayedPaymentStatus: boolean;
  chargeOpenedPaymentStatus: boolean;
  chargeExpiredPaymentStatus: boolean;
  chargeCanceledPaymentStatus: boolean;
  chargeDefaultingPaymentStatus: boolean;
  cooperativeLegalName: string;
  cooperativeMemberLegalName: string;
  powerDistributionUnitLegalName: string;
  consumerUnitPowerDistributionUnitIdentifier: string;
};

export type FormErrors = {
  chargeDate?: FieldError;
  cooperativeLegalName?: FieldError;
  cooperativeMemberLegalName?: FieldError;
  powerDistributionUnitLegalName?: FieldError;
  consumerUnitPowerDistributionUnitIdentifier?: FieldError;
  chargePaymentStatus?: FieldError;
  chargePaymentEndDate?: FieldError;
  chargePaymentStartDate?: FieldError;
  chargeCreationEndDate?: FieldError;
  chargeCreationStartDate?: FieldError;
  chargeExpirationEndDate?: FieldError;
  chargeExpirationStartDate?: FieldError;
  chargePayedPaymentStatus?: FieldError;
  chargeOpenedPaymentStatus?: FieldError;
  chargeExpiredPaymentStatus?: FieldError;
  chargeCanceledPaymentStatus?: FieldError;
  chargeDefaultingPaymentStatus?: FieldError;
};

export default function ChargesFormFilters({
  errors,
  control,
  register,
  setValue,
  isLoading,
  hasActiveFilters,
}: {
  errors: FormErrors;
  isLoading?: boolean;
  control: Control<FormFilters>;
  setValue: UseFormSetValue<FormFilters>;
  register: UseFormRegister<FormFilters>;
  hasActiveFilters: (value: boolean) => void;
}) {
  const CHARGE_PAYMENT_STATUS = [
    {
      label: 'Pago',
      value: ChargeStatus.PAYED,
      name: 'chargePayedPaymentStatus' as const,
    },
    {
      label: 'Vencido',
      value: ChargeStatus.OVERDUE,
      name: 'chargeExpiredPaymentStatus' as const,
    },
    {
      label: 'Cancelado',
      value: ChargeStatus.CANCELED,
      name: 'chargeCanceledPaymentStatus' as const,
    },
    {
      label: 'Em Aberto',
      value: ChargeStatus.OPENED,
      name: 'chargeOpenedPaymentStatus' as const,
    },
    {
      label: 'Inadimplente',
      value: ChargeStatus.DEFAULTER,
      name: 'chargeDefaultingPaymentStatus' as const,
    },
  ];

  const CHARGE_TEXT_FILTERS = useMemo(
    () => [
      {
        label: 'Unidade Geradora',
        placeholder: 'Digitar',
        id: 'powerDistributionUnitLegalName' as const,
        error: errors.powerDistributionUnitLegalName?.message,
      },
      {
        label: 'Cooperativa',
        placeholder: 'Digitar',
        id: 'cooperativeLegalName' as const,
        error: errors.cooperativeLegalName?.message,
      },
      {
        label: 'Cooperados',
        placeholder: 'Digitar',
        id: 'cooperativeMemberLegalName' as const,
        error: errors.cooperativeMemberLegalName?.message,
      },
      {
        label: "UC's",
        placeholder: 'Digitar',
        id: 'consumerUnitPowerDistributionUnitIdentifier' as const,
        error: errors.consumerUnitPowerDistributionUnitIdentifier?.message,
      },
      {
        maxLength: 7,
        mask: 'date',
        placeholder: 'MM/AAAA',
        label: 'Mês da fatura',
        id: 'chargeDate' as const,
        error: errors.chargeDate?.message,
      },
    ],
    [errors]
  );

  const CHARGE_DATE_FILTERS = useMemo(
    () => [
      {
        id: 1,
        label: 'Criação',
        name: 'chargeCreationDate' as const,
        error: {
          endDateInput: errors.chargeCreationEndDate?.message,
          startDateInput: errors.chargeCreationStartDate?.message,
        },
      },
      {
        id: 2,
        label: 'Vencimento',
        name: 'chargeExpirationDate' as const,
        error: {
          endDateInput: errors.chargeExpirationEndDate?.message,
          startDateInput: errors.chargeExpirationStartDate?.message,
        },
      },
      {
        id: 3,
        label: 'Pagamento',
        name: 'chargePaymentDate' as const,
        error: {
          endDateInput: errors.chargePaymentEndDate?.message,
          startDateInput: errors.chargePaymentStartDate?.message,
        },
      },
    ],
    [errors]
  );

  const activeFiltersCount = useWatch({
    control,
    name: [
      'chargeDate',
      'cooperativeLegalName',
      'cooperativeMemberLegalName',
      'powerDistributionUnitLegalName',
      'consumerUnitPowerDistributionUnitIdentifier',
      'chargePayedPaymentStatus',
      'chargeOpenedPaymentStatus',
      'chargeExpiredPaymentStatus',
      'chargeCanceledPaymentStatus',
      'chargeDefaultingPaymentStatus',
      'chargePaymentDate',
      'chargeCreationDate',
      'chargeExpirationDate',
    ],
  }).filter((filter) => filter).length;

  useEffect(() => {
    activeFiltersCount > 0 ? hasActiveFilters(true) : hasActiveFilters(false);
  }, [activeFiltersCount, hasActiveFilters]);

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const handleFiltersOpen = useCallback(() => {
    setIsFiltersOpen(!isFiltersOpen);
  }, [isFiltersOpen]);

  function handleClearFilters() {
    CHARGE_TEXT_FILTERS.forEach((filter) => setValue(filter.id, ''));

    CHARGE_PAYMENT_STATUS.forEach((status) => {
      setValue(status.name, false, {
        shouldValidate: true,
      });
    });

    CHARGE_DATE_FILTERS.forEach((filter) => {
      setValue(filter.name, undefined, {
        shouldValidate: true,
      });
    });
  }

  return (
    <div className="w-full flex flex-col gap-4 items-start justify-start mb-3">
      <div className="flex flex-col gap-1">
        <p className="font-bold text-gray-dark400">Filtros avançados</p>
        <Button
          size="md"
          type="button"
          variant="advancedFilter"
          onClick={handleFiltersOpen}
          className="flex items-center justify-center"
        >
          <p className="text-sm font-medium">Filtros ({activeFiltersCount})</p>
          {isFiltersOpen ? (
            <MdKeyboardArrowUp size={20} />
          ) : (
            <MdKeyboardArrowDown size={20} />
          )}
        </Button>
      </div>
      {isFiltersOpen && (
        <div className="w-full flex flex-col gap-4 items-start justify-start bg-gray-dark700 rounded-lg">
          <div className="flex gap-12 items-start justify-start p-7">
            <div className="flex flex-col gap-4 items-start justify-start">
              <p className="font-bold text-gray-dark400">Selecionar Entidades</p>
              <div className="flex gap-4 items-start">
                {CHARGE_TEXT_FILTERS.slice(0, 3).map((filter) => (
                  <TextField
                    id={filter.id}
                    key={filter.id}
                    error={filter.error}
                    label={filter.label}
                    maxLength={filter?.maxLength}
                    placeholder={filter.placeholder}
                    mask={filter?.mask as MaskInterface}
                    {...register(filter.id)}
                  />
                ))}
              </div>
              <div className="flex gap-4 items-start">
                {CHARGE_TEXT_FILTERS.slice(3, 5).map((filter) => (
                  <TextField
                    id={filter.id}
                    key={filter.id}
                    error={filter.error}
                    label={filter.label}
                    maxLength={filter?.maxLength}
                    placeholder={filter.placeholder}
                    mask={filter?.mask as MaskInterface}
                    {...register(filter.id)}
                  />
                ))}
              </div>
            </div>

            <div className="flex flex-col items-start justify-start">
              <p className="font-bold text-gray-dark400">Status de pagamento</p>
              {CHARGE_PAYMENT_STATUS.map((status) => (
                <CheckboxField
                  key={status.value}
                  inputLabel={status.label}
                  {...register(status.name)}
                />
              ))}
            </div>

            <div className="flex flex-col gap-4 items-start justify-start">
              <p className="font-bold text-gray-dark400">Período da consulta</p>
              <div className="flex items-center justify-start gap-4">
                {CHARGE_DATE_FILTERS.map((date) => (
                  <Controller
                    key={date.id}
                    name={date.name}
                    control={control}
                    render={({ field }) => (
                      <DatePickerFieldDobleView
                        {...omit(field, 'ref')}
                        selectRange
                        endLabel="Até"
                        id={date.name}
                        label={date.label}
                        error={date.error}
                        placeholder="DD/MM/AAAA"
                        endPlaceholder="DD/MM/AAAA"
                        calendarWrapperClassName="z-10"
                        rangeInputWrapperClassName="flex flex-col items-start justify-center gap-4"
                      />
                    )}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="w-full flex items-center justify-between border-t border-gray-dark500 p-7">
            <p className="font-bold flex text-gray-dark500 w-full">
              ({activeFiltersCount}) Filtros selecionados
            </p>
            <div className="w-full flex gap-4 items-center justify-end">
              <Button
                size="sm"
                type="button"
                className="w-24"
                disabled={isLoading}
                variant="secondaryGray"
                onClick={handleClearFilters}
              >
                Limpar
              </Button>
              <Button
                size="sm"
                type="submit"
                disabled={isLoading || !activeFiltersCount}
              >
                {`Aplicar ${activeFiltersCount} filtros`}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
