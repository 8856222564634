import { MdAssignmentTurnedIn, MdCircle, MdMonetizationOn } from 'react-icons/md';

import { TransfersCounter } from 'transfers/models/transfers';
import { convertToBRL } from 'utils/form';

export default function TransfersCounterItem({
  transfersCounter,
}: {
  transfersCounter?: TransfersCounter;
}) {
  return (
    <div className="w-full items-center justify-start flex gap-8 pb-10">
      <div className="flex items-center justify-start gap-4">
        <MdAssignmentTurnedIn className="text-gray-dark400" size={25} />
        <p className="flex items-center justify-start font-medium text-gray-dark500 gap-1">
          <span className="font-bold">
            {transfersCounter?.transfersAccomplishedStatusCount || 0}
          </span>{' '}
          Transferências realizadas
        </p>
      </div>
      <div className="flex items-center justify-start gap-4">
        <MdCircle className="text-red-500" size={10} />
        <p className="flex items-center justify-start font-medium text-gray-dark500 gap-1">
          <span className="font-bold">
            {transfersCounter?.transfersFailedStatusCount || 0}
          </span>{' '}
          Falhas de transferências
        </p>
      </div>
      <div className="flex items-center justify-start gap-4">
        <MdMonetizationOn className="text-gray-dark400" size={25} />
        <p className="flex items-center justify-start font-medium text-gray-dark500 gap-1">
          Total de:
          <span className="font-bold">
            {convertToBRL(transfersCounter?.transfersTotalAmount || '0')}
          </span>
        </p>
      </div>
    </div>
  );
}
