import {
  MdLocalOffer,
  MdMonetizationOn,
  MdSwapVerticalCircle,
} from 'react-icons/md';

import { DetailedTransfersCounter } from 'transfers/detailedTransfers/models/detailedTransfers';
import { convertToBRL } from 'utils/form';

export default function DetailedTransfersCounterItem({
  detailedTransfersCounter,
}: {
  detailedTransfersCounter?: DetailedTransfersCounter;
}) {
  return (
    <div className="w-full items-center justify-start flex gap-8 pb-10">
      <div className="flex items-center justify-start gap-4">
        <MdSwapVerticalCircle className="text-gray-dark500" size={25} />
        <p className="flex items-center justify-start font-medium text-gray-dark500 gap-1">
          <span className="font-bold">
            {detailedTransfersCounter?.chargesCount || 0}
          </span>{' '}
          Total de cobranças
        </p>
      </div>
      <div className="flex items-center justify-start gap-4">
        <MdMonetizationOn className="text-gray-dark500" size={25} />
        <p className="flex items-center justify-start font-medium text-gray-dark500 gap-1">
          <span className="font-bold">
            {convertToBRL(detailedTransfersCounter?.transfersTotalAmount as string)}
          </span>{' '}
          Total transferido
        </p>
      </div>
      <div className="flex items-center justify-start gap-4">
        <MdMonetizationOn className="text-gray-dark500" size={25} />
        <p className="flex items-center justify-start font-medium text-gray-dark500 gap-1">
          <span className="font-bold">
            {convertToBRL(
              detailedTransfersCounter?.transfersOriginalValue as string
            )}
          </span>{' '}
          Valor original
        </p>
      </div>
      <div className="flex items-center justify-start gap-4">
        <MdLocalOffer className="text-gray-dark500" size={25} />
        <p className="flex items-center justify-start font-medium text-gray-dark500 gap-1">
          <span className="font-bold">
            {convertToBRL(detailedTransfersCounter?.transfersFinesValue as string)}
          </span>{' '}
          Multas
        </p>
      </div>
      <div className="flex items-center justify-start gap-4">
        <MdLocalOffer className="text-gray-dark500" size={25} />
        <p className="flex items-center justify-start font-medium text-gray-dark500 gap-1">
          <span className="font-bold">
            {convertToBRL(detailedTransfersCounter?.transfersFeesValue as string)}
          </span>{' '}
          Juros
        </p>
      </div>
    </div>
  );
}
