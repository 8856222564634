import { PowerDistributionUnit as PowerDistributionUnitModel } from 'powerDistributionUnits/models/powerDistributionUnit';

export const PowerDistributionUnit = (
  id = new Date().valueOf()
): PowerDistributionUnitModel => ({
  id,
  powerDistributionUnitCountryState: 'SC',
  powerDistributionUnitDocumentId: '9876',
  powerDistributionUnitLegalName: `Power Distribution Units Company ${id}`,
  powerDistributionUnitDeletedAt: null,
});

export default PowerDistributionUnit;
