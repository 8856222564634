import {
  ChargeStatus,
  ChargePaymentMethods,
  Charges as ChargesModel,
} from 'charges/models/charges';

import ConsumerUnit from './consumerUnit';
import Cooperative from './cooperative';
import CooperativeMember from './cooperativeMember';
import GenerationUnit from './generationUnit';
import ConsumerUnitBillData from './consumerUnitBillData';
import { CommercialAgreement } from './commercialAgreement';
import EconomyReport from './economyReport';

export const Charges = (
  id = new Date().valueOf(),
  chargesStatus = ChargeStatus.OVERDUE,
  chargePaymentMethod = ChargePaymentMethods.PIX
): ChargesModel => ({
  id: id,
  consumerUnit: ConsumerUnit(id),
  cooperative: Cooperative(id),
  cooperativeMember: CooperativeMember(id),
  economyReport: EconomyReport(id),
  generationUnit: GenerationUnit(id),
  comercialAgreement: CommercialAgreement(id),
  powerDistributionUnitBillData: ConsumerUnitBillData(id),
  chargeStatus: chargesStatus,
  chargeDueDate: '2023-01-01',
  chargeInterest: `${id + 100}`,
  chargeTotalPaid: `${id + 100}`,
  chargePaymentDate: '2023-01-02 02:00:00',
  chargeCreatedDate: '2023-01-01 02:00:00:',
  chargeTotalBilled: `${id + 100}`,
  chargeTotalCharged: `${id + 100}`,
  chargeAppTotalFees: `${id + 100}`,
  chargePaymentMethod: chargePaymentMethod,
  chargeCooperativeTotalValue: `${id + 100}`,
  chargeGenerationUnitTotalValue: `${id + 100}`,
  chargeInvoiceUrl: 'https://www.nexenergy.com.br',
  chargeGenerationUnitOemTotalValue: `${id + 100}`,
  chargeCooperativeSubscriptionValue: `${id + 100}`,
  chargeGenerationUnitRealStateTotalValue: `${id + 100}`,
  chargeGenerationUnitEquipmentRentTotalValue: `${id + 100}`,
});

export default Charges;
