import Table from 'ui/components/table/Table';
import TableBody from 'ui/components/table/TableBody';
import TableHead from 'ui/components/table/TableHead';
import TableHeadColumn from 'ui/components/table/TableHeadColumn';

import DetailedTransfersTableItem from './DetailedTransfersTableItem';

import { DetailedTransfers } from 'transfers/detailedTransfers/models/detailedTransfers';

type Props = {
  detailedTransfers: DetailedTransfers[];
};

export default function DetailedTransfersTable({ detailedTransfers }: Props) {
  return (
    <Table>
      <TableHead>
        <TableHeadColumn>ID</TableHeadColumn>
        <TableHeadColumn>UC</TableHeadColumn>
        <TableHeadColumn>COOPERADO</TableHeadColumn>
        <TableHeadColumn>VALOR</TableHeadColumn>
        <TableHeadColumn>MULTAS</TableHeadColumn>
        <TableHeadColumn>JUROS</TableHeadColumn>
        <TableHeadColumn>DATA PAG.</TableHeadColumn>
      </TableHead>
      <TableBody>
        {detailedTransfers.map((detailedTransfer: DetailedTransfers) => (
          <DetailedTransfersTableItem
            key={detailedTransfer.id}
            detailedTransfers={detailedTransfer}
          />
        ))}
      </TableBody>
    </Table>
  );
}
