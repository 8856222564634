import React from 'react';
import classNames from 'classnames';

const ButtonClassnames = {
  variants: {
    primaryGreen:
      'bg-primary text-gray-dark800 border-none font-bold hover:bg-success',
    primaryGray:
      'bg-gray-dark550 text-gray-dark400 border-none font-bold hover:bg-gray-dark600',
    primaryRed: 'bg-red-a1 text-white hover:bg-red-a2 font-bold',
    link: 'outline-none bg-transparent hover:text-primary-dark700 focus:outline-none group text-white font-bold',
    icon: 'outline-none text-gray-dark500 hover:text-gray-dark400 focus:outline-none',
    circular:
      'inline-flex items-center border border-primary shadow-sm text-gray-dark-11 bg-primary hover:bg-primary-dark200 focus:outline-none focus:ring-4 focus:ring-primary focus:ring-opacity-50',
    secondaryGray:
      'outline-none border border-gray-dark500 text-gray-dark400 hover:border-gray-dark600 hover:text-gray-dark500',
    tertiaryGray:
      'outline-none text-gray-dark400 bg-gray-dark550 hover:border-gray-dark600 hover:bg-gray-dark600',
    secondaryRed:
      'text-red-a1 border border-red-a1 bg-transparent hover:border-red-a2 hover:text-red-a2 focus:ring-0 focus:ring-transparent',
    advancedFilter:
      'appearence-none border-gray-dark500 border bg-gray-dark600 shadow-none rounded-md text-gray-dark400',
  },
  sizes: {
    sm: 'py-3 px-6',
    md: 'py-3 px-11',
    none: '',
  },
  rounded: {
    md: 'rounded',
    lg: 'rounded-lg',
    circular: 'rounded-full',
  },
};

export type Props = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    ref?: React.Ref<HTMLButtonElement>;
    variant?: keyof typeof ButtonClassnames.variants;
    size?: keyof typeof ButtonClassnames.sizes;
    rounded?: keyof typeof ButtonClassnames.rounded;
  },
  HTMLButtonElement
>;

export const Button = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      variant = 'primaryGreen',
      size = 'md',
      rounded = 'md',
      type = 'button',
      className = '',
      children,
      ...props
    },
    ref
  ) => {
    const buttonClasses = classNames(
      'text-xs inline-flex items-center justify-center gap-x-2',
      ButtonClassnames.variants[variant],
      ButtonClassnames.sizes[size],
      ButtonClassnames.rounded[rounded],
      className
    );

    return (
      <button type={type} className={buttonClasses} ref={ref} {...props}>
        {children}
      </button>
    );
  }
);

export default Button;
