import {
  CommercialAgreementReceiptRuleType,
  CommercialAgreementValidityStatusType,
} from 'commercialAgreements/models/commercialAgreement';
import { CommercialAgreement as CommercialAgreementModel } from 'commercialAgreements/models/commercialAgreement';

export const CommercialAgreement = (
  id = new Date().valueOf(),
  commercialAgreementStartValidity = new Date(2022, 7, 8),
  commercialAgreementReceiptRule = CommercialAgreementReceiptRuleType.WEEKLY,
  commercialAgreementValidityStatus = CommercialAgreementValidityStatusType.ACTUAL
): CommercialAgreementModel => ({
  id,
  commercialAgreementStartValidity,
  commercialAgreementReceiptRule,
  commercialAgreementHasPisCofins: true,
  commercialAgreementHasExtraRemuneration: false,
  commercialAgreementLeaseValue: `${id * 1000}`,
  commercialAgreementOemValue: `${id * 20}`,
  commercialAgreementHasOem: true,
  commercialAgreementDiscountMax: `20`,
  commercialAgreementDiscountMin: `15`,
  commercialAgreementBaseCostPerCreditIcms: `${id * 132}`,
  commercialAgreementBaseCostPerCredit: `${id * 123}`,
  commercialAgreementValidityStatus,
  generationUnit: {
    id: id,
    generationUnitLegalName: `Generation unit legal name ${id}`,
  },
});
