import { Switch, Route } from 'react-router-dom';
import { LoginPage, NewPasswordPage } from 'auth/pages/login';
import { Dashboard } from 'dashboard/components/dashboard';
import PrivateRoute from './PrivateRoute';
import {
  ListInternalUsersPage,
  CreateInternalUsersPage,
  EditInternalUsersPage,
} from 'internalUsers/pages';
import {
  ListPowerDistributionUnitsPage,
  CreatePowerDistributionUnitsPage,
  EditPowerDistributionUnitsPage,
} from 'powerDistributionUnits/pages';
import {
  ListTariffGroups,
  CreateTariffGroupPage,
  EditTariffGroupPage,
} from 'tariffGroups/pages';
import {
  CreateCooperativesPage,
  ListCooperativesPage,
  EditCooperativesPage,
  ListCooperativeMembersPage,
  CreateCooperativeMembersPage,
  EditCooperativeMembersPage,
} from 'cooperatives/pages';
import {
  ListPisCofinsTaxesPage,
  CreatePisCofinsTaxesPage,
  EditPisCofinsTaxesPage,
} from 'pisCofinsTaxes/pages';
import {
  ListIcmsTaxesPage,
  CreateIcmsTaxesPage,
  EditIcmsTaxesPage,
} from 'icmsTaxes/pages';
import { UiDashboardPage } from 'uiDashboard/pages';
import {
  ListConsumerUnitsPage,
  CreateConsumerUnitsPage,
  EditConsumerUnitsPage,
} from 'consumerUnits/pages';
import {
  ListFinancialAccountsPage,
  CreateFinancialAccountsPage,
  EditFinancialAccountsPage,
} from 'financialAccounts/pages';
import { ListGenerationUnitLeaseCyclesPage } from 'generationUnits/generationUnitLeaseCycles/pages';
import { ListGenerationUnitsPage } from 'generationUnits/pages';
import { CreateGenerationUnitsPage } from 'generationUnits/pages';
import EditGenerationUnitsPage from 'generationUnits/pages/EditGenerationUnits';
import ListTariffFlagsPage from 'tariffFlagTariffs/pages/ListTariffFlagModalities';
import { ListApportionmentCycleConsumerUnitsPage } from 'apportionmentCycles/pages';
import { ListCustomApportionmentCycleConsumerUnitsPage } from 'customApportionmentCycleConsumerUnit/pages';
import {
  ListCommercialAgreementsPage,
  CreateCommercialAgreementsPage,
  EditCommercialAgreementsPage,
} from 'commercialAgreements/pages';
import { CreateGenerationUnitBillDataPage } from 'powerDistributionUnits/powerDistributionUnitBillData/generationUnitBillData/pages';
import { ListConsumerUnitBillingCyclesPage } from 'consumerUnits/consumerUnitBillingCycles/pages';
import { CreateConsumerUnitBillDataPage } from 'powerDistributionUnits/powerDistributionUnitBillData/consumerUnitBillData/pages';
import EconomyReport from 'economyReport/pages/EconomyReport';
import { ListCharges } from 'charges/pages';
import { ListTransfers } from 'transfers/pages';
import { ListDetailedTransfers } from 'transfers/detailedTransfers/pages';

const Routes = () => (
  <Switch>
    <Route path="/dashboard/ui" component={UiDashboardPage} />

    <Route path="/dashboard/docs/economy-report/:id" component={EconomyReport} />

    <PrivateRoute
      path="/dashboard/internal-users/create"
      component={CreateInternalUsersPage}
    />
    <PrivateRoute
      path="/dashboard/internal-users/:id/edit"
      component={EditInternalUsersPage}
    />
    <PrivateRoute
      path="/dashboard/internal-users"
      component={ListInternalUsersPage}
    />

    <PrivateRoute
      path="/dashboard/business-entities/cooperatives/create"
      component={CreateCooperativesPage}
    />
    <PrivateRoute
      path="/dashboard/business-entities/cooperatives/:id/edit"
      component={EditCooperativesPage}
    />
    <PrivateRoute
      path="/dashboard/business-entities/cooperatives"
      component={ListCooperativesPage}
    />
    <PrivateRoute
      path="/dashboard/business-entities/cooperative-members/create"
      component={CreateCooperativeMembersPage}
    />
    <PrivateRoute
      path="/dashboard/business-entities/cooperative-members/:id/edit"
      component={EditCooperativeMembersPage}
    />
    <PrivateRoute
      path="/dashboard/business-entities/cooperative-members"
      component={ListCooperativeMembersPage}
    />
    <PrivateRoute
      path="/dashboard/business-entities/consumer-units/create"
      component={CreateConsumerUnitsPage}
    />
    <PrivateRoute
      path="/dashboard/business-entities/consumer-units/:id/edit"
      component={EditConsumerUnitsPage}
    />
    <PrivateRoute
      path="/dashboard/business-entities/consumer-units"
      component={ListConsumerUnitsPage}
    />
    <PrivateRoute
      path="/dashboard/business-entities/power-distribution-units/create"
      component={CreatePowerDistributionUnitsPage}
    />
    <PrivateRoute
      path="/dashboard/business-entities/power-distribution-units/:id/edit"
      component={EditPowerDistributionUnitsPage}
    />
    <PrivateRoute
      path="/dashboard/business-entities/power-distribution-units"
      component={ListPowerDistributionUnitsPage}
    />
    <PrivateRoute path="/dashboard/billing-cycle/charges" component={ListCharges} />
    <PrivateRoute
      path="/dashboard/billing-cycle/transfers/detailed-transfers"
      component={ListDetailedTransfers}
    />

    <PrivateRoute
      path="/dashboard/billing-cycle/transfers"
      component={ListTransfers}
    />

    <PrivateRoute
      path="/dashboard/billing-cycle/financial-accounts/create"
      component={CreateFinancialAccountsPage}
    />
    <PrivateRoute
      path="/dashboard/billing-cycle/financial-accounts/:id/edit"
      component={EditFinancialAccountsPage}
    />
    <PrivateRoute
      path="/dashboard/billing-cycle/financial-accounts"
      component={ListFinancialAccountsPage}
    />
    <PrivateRoute
      path="/dashboard/business-entities/generation-units/create"
      component={CreateGenerationUnitsPage}
    />
    <PrivateRoute
      path="/dashboard/business-entities/generation-units/:id/edit"
      component={EditGenerationUnitsPage}
    />
    <PrivateRoute
      path="/dashboard/business-entities/generation-units/create"
      component={CreateGenerationUnitsPage}
    />
    <PrivateRoute
      path="/dashboard/business-entities/generation-units"
      component={ListGenerationUnitsPage}
    />

    <PrivateRoute
      path="/dashboard/bill-data/generation-units/create"
      component={CreateGenerationUnitBillDataPage}
    />
    <PrivateRoute
      path="/dashboard/billing-cycle/lease-cycles"
      component={ListGenerationUnitLeaseCyclesPage}
    />
    <PrivateRoute
      path="/dashboard/billing-cycle/financial-accounts/create"
      component={CreateFinancialAccountsPage}
    />
    <PrivateRoute
      path="/dashboard/billing-cycle/financial-accounts/:id/edit"
      component={EditFinancialAccountsPage}
    />
    <PrivateRoute
      path="/dashboard/billing-cycle/financial-accounts"
      component={ListFinancialAccountsPage}
    />
    <PrivateRoute
      path="/dashboard/billing-cycle/apportionment-cycle/credit-distribution"
      component={ListApportionmentCycleConsumerUnitsPage}
    />
    <PrivateRoute
      path="/dashboard/billing-cycle/apportionment-cycle"
      component={ListCustomApportionmentCycleConsumerUnitsPage}
    />
    <PrivateRoute
      path="/dashboard/billing-cycle/commercial-agreements/create"
      component={CreateCommercialAgreementsPage}
    />
    <PrivateRoute
      path="/dashboard/billing-cycle/commercial-agreements/:id/edit"
      component={EditCommercialAgreementsPage}
    />
    <PrivateRoute
      path="/dashboard/billing-cycle/commercial-agreements"
      component={ListCommercialAgreementsPage}
    />
    <PrivateRoute
      path="/dashboard/bill-data/consumer-units/create"
      component={CreateConsumerUnitBillDataPage}
    />
    <PrivateRoute
      path="/dashboard/billing-cycle/billing-cycles"
      component={ListConsumerUnitBillingCyclesPage}
    />

    <PrivateRoute
      path="/dashboard/taxes-fees/tariff-groups/create"
      component={CreateTariffGroupPage}
    />
    <PrivateRoute
      path="/dashboard/taxes-fees/tariff-groups/:id/edit"
      component={EditTariffGroupPage}
    />
    <PrivateRoute
      path="/dashboard/taxes-fees/tariff-groups"
      component={ListTariffGroups}
    />
    <PrivateRoute
      path="/dashboard/taxes-fees/icms-taxes/create"
      component={CreateIcmsTaxesPage}
    />
    <PrivateRoute
      path="/dashboard/taxes-fees/icms-taxes/:id/edit"
      component={EditIcmsTaxesPage}
    />
    <PrivateRoute
      path="/dashboard/taxes-fees/icms-taxes"
      component={ListIcmsTaxesPage}
    />
    <PrivateRoute
      path="/dashboard/taxes-fees/pis-cofins-taxes/create"
      component={CreatePisCofinsTaxesPage}
    />
    <PrivateRoute
      path="/dashboard/taxes-fees/pis-cofins-taxes/:id/edit"
      component={EditPisCofinsTaxesPage}
    />
    <PrivateRoute
      path="/dashboard/taxes-fees/pis-cofins-taxes"
      component={ListPisCofinsTaxesPage}
    />
    <PrivateRoute
      path="/dashboard/taxes-fees/tariff-flags"
      component={ListTariffFlagsPage}
    />

    <PrivateRoute path="/dashboard" component={Dashboard} />
    <Route path="/reset-password" component={NewPasswordPage} />
    <Route path="/" component={LoginPage} />
  </Switch>
);

export default Routes;
