import {
  FinancialAccountType,
  FinancialAccountEntityType,
  FinancialAccount as FinancialAccountModel,
} from 'financialAccounts/models/financialAccounts';
import Bank from './bank';

export const FinancialAccount = (
  id = new Date().valueOf(),
  financialAccountType = FinancialAccountType.CHECKING,
  financialAccountEntityType = FinancialAccountEntityType.INDIVIDUAL
): FinancialAccountModel => ({
  id,
  bankId: id,
  bank: Bank(id),
  financialAccountType,
  financialAccountEntityType,
  financialAccountAddressState: 'MG',
  financialAccountDocumentId: `${id}`,
  financialAccountBankCc: `Conta ${id}`,
  financialAccountBankAg: `Agência ${id}`,
  financialAccountAddressPostalCode: 37405000,
  financialAccountAddressCity: `Belo Horizonte`,
  financialAccountAddressDistrict: `Centro ${id}`,
  financialAccountAddressComplement: `Casa ${id}`,
  financialAccountAddressStreet: `Rua dos Bobos ${id}`,
  financialAccountLegalName: `Financial Account ${id}`,
});

export default FinancialAccount;
