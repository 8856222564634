import { omit } from 'lodash';

import { useCSVDownloader } from 'react-papaparse';

import { Controller, useForm, useWatch } from 'react-hook-form';

import { Button } from 'ui';
import RadioField from 'ui/form/RadioField';
import { useLoading } from 'ui/contexts/overlay/Loading';
import { ModalFormProps, ModalProps } from 'ui/models/overlay';

import { TransferExportType } from 'transfers/models/transfers';

type Props = {
  modal: ModalProps;
  onClickContinue?: () => void;
  onClickCloseModal: () => void;
  ModalOverlay: (any: ModalFormProps) => JSX.Element;
};

type ExportModalFields = {
  transferExportType: TransferExportType;
};

export default function TransfersExportModal({
  modal,
  ModalOverlay,
  onClickContinue,
  onClickCloseModal,
}: Props) {
  const { control } = useForm<ExportModalFields>();

  const { LoadingOverlay } = useLoading();
  const { CSVDownloader, Type } = useCSVDownloader();

  const MODAL_EXPORT_TYPE_OPTIONS = [
    {
      label: 'Resumido',
      value: TransferExportType.SUMMEDUP,
    },
    {
      label: 'Com Detalhes',
      value: TransferExportType.DETAILED,
    },
  ];

  const [transferExportType] = useWatch({
    control,
    name: ['transferExportType'],
  });

  return (
    <ModalOverlay title={modal.title} variant={modal.variant}>
      <div className="flex flex-col gap-2 p-4">
        <p className="text-gray-dark400 font-small text-sm font-semibold">
          {modal.text}
        </p>
        <div className="flex gap-4">
          <Controller
            control={control}
            name="transferExportType"
            render={({ field: { ref, value, onChange } }) => (
              <RadioField
                {...omit(ref, 'ref', 'value')}
                value={value}
                onChange={onChange}
                id="transferExportType"
                options={MODAL_EXPORT_TYPE_OPTIONS}
              />
            )}
          />
        </div>
      </div>
      <div className="flex w-full items-center justify-end flex-row-reverse mt-8 gap-4">
        <CSVDownloader
          data={
            /* REFACTOR: WE NEED TO INCLUDE THE RIGHT DATA BELLOW */
            (transferExportType === TransferExportType.SUMMEDUP && [['SUMMED']]) ||
            (transferExportType === TransferExportType.DETAILED && [['DETAILED']])
          }
          bom={true}
          type={Type.Link}
          filename="Relatório de Transferência"
          config={{
            delimiter: ';',
          }}
        >
          <Button
            size="md"
            variant="primaryGreen"
            onClick={transferExportType && onClickContinue}
            disabled={!transferExportType}
          >
            <p className="font-bold">Baixar</p>
          </Button>
        </CSVDownloader>

        <Button variant="primaryGray" size="md" onClick={onClickCloseModal}>
          <p className="font-medium ">Cancelar</p>
        </Button>
      </div>
      <LoadingOverlay />
    </ModalOverlay>
  );
}
