import { useHistory } from 'react-router-dom';

import { MdCircle } from 'react-icons/md';
import { AiFillEye } from 'react-icons/ai';

import { Tooltip } from 'ui/components/tooltip';
import { TableBodyColumn } from 'ui/components/table';
import TableBodyRow from 'ui/components/table/TableBodyRow';

import { Transfers, TransferStatus } from 'transfers/models/transfers';
import { convertToBRL, formatDateToLocaleDateString } from 'utils/form';

type Props = {
  transfers: Transfers;
};

export default function TransfersTableItem({
  transfers: {
    id,
    transferDate,
    transferValue,
    transferStatus,
    financialAccount,
    transferRequestDate,
  },
}: Props) {
  const { push } = useHistory();

  const DETAILED_TRANSFERS_ROUTE =
    '/dashboard/billing-cycle/transfers/detailed-transfers';

  const STATUS = {
    [TransferStatus.PENDING]: 'Pendente',
    [TransferStatus.ACCOMPLISHED]: 'Realizado',
    [TransferStatus.FAILED]: (
      <div className="flex items-center justify-start gap-4">
        <MdCircle className="text-red-500" size={10} />
        <p className="font-semibold">Falha</p>
      </div>
    ),
  };

  return (
    <TableBodyRow dataTestId={`transfers-row-id-${id}`}>
      <TableBodyColumn>{id}</TableBodyColumn>
      <TableBodyColumn>
        <Tooltip
          arrowPosition="top"
          placement="bottom-start"
          offset={{ mainAxis: 5 }}
          label={
            <div className="w-full flex flex-col items-start justify-center">
              <p className="font-light text-sm">
                Conta financeira: <br />
                <span className="font-semibold leading-5">
                  {financialAccount.financialAccountLegalName}
                </span>
              </p>
              <p className="font-light text-sm ">
                Documento: <br />
                <span className="font-semibold leading-5">
                  {financialAccount.financialAccountDocumentId}
                </span>
              </p>
              <p className="font-light text-sm">
                Dados bancários: <br />
                <span className="font-semibold leading-5">
                  {financialAccount.financialAccountBankAg} <br />
                  {financialAccount.financialAccountBankCc} <br />
                </span>
              </p>
            </div>
          }
        >
          <p className="underline w-full flex">
            {financialAccount.financialAccountLegalName}
          </p>
        </Tooltip>
      </TableBodyColumn>
      <TableBodyColumn>
        {formatDateToLocaleDateString(transferRequestDate)}
      </TableBodyColumn>
      <TableBodyColumn>{formatDateToLocaleDateString(transferDate)}</TableBodyColumn>
      <TableBodyColumn>{convertToBRL(transferValue)}</TableBodyColumn>
      <TableBodyColumn>{STATUS[transferStatus]}</TableBodyColumn>
      <TableBodyColumn className="font-normal flex items-center justify-center">
        <AiFillEye
          className="cursor-pointer text-gray-dark500 hover:text-gray-dark400"
          size={20}
          onClick={() => {
            push({
              pathname: DETAILED_TRANSFERS_ROUTE,
              state: {},
            });
          }}
        />
      </TableBodyColumn>
    </TableBodyRow>
  );
}
