import { useEffect, useState } from 'react';

import { MdCloudDownload } from 'react-icons/md';

import { SubmitHandler, useForm } from 'react-hook-form';

import { useLazyQuery } from '@apollo/client';

import { useCSVDownloader } from 'react-papaparse';

import { INITIAL_QUERY_STATE_CONFIG } from 'graphql/apollo/config';

import { Button } from 'ui';
import { ModalProps } from 'ui/models/overlay';
import useToastContext from 'ui/hooks/useToast';
import { useModal } from 'ui/contexts/overlay/Modal';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useLoading } from 'ui/contexts/overlay/Loading';

import { Pagination } from 'dashboard/components/table';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';

import { Charges } from 'charges/models/charges';
import { CHARGES_QUERY } from 'charges/graphql/chargesQuerys';
import ChargesTable from 'charges/components/table/ChargesTable';
import ChargesAlertModal from 'charges/components/modal/ChargesAlertModal';
import ChargesFormFilters, {
  FormFilters,
} from 'charges/components/form/ChargesFormFilters';

import { unMaskFormFields } from 'utils/unmask';

type State = {
  charges: Charges[];
  hasNextPage: boolean;
  hasPreviousPage: boolean;
};

const LIST_ERROR_TOAST: ToastProps = {
  variant: 'danger',
  title: 'Algo deu errado!',
  text: 'Não foi possível carregar a lista de Cobranças',
};

const EXPORT_SUCCESS_TOAST: ToastProps = {
  variant: 'primary',
  title: 'Sucesso!',
  text: 'Sucesso ao baixar Relatório Financeiro',
};

const EXPORT_ALERT_MODAL: ModalProps = {
  variant: 'alert',
  title: 'Atenção!',
  text: 'Caso nenhum filtro seja selecionado, será exportado toda a base de dados dessa tela. Deseja continuar mesmo assim?',
};

const DASHBOARD_TITLE = 'Cobranças';

export default function ListChargesPage() {
  const { addToast } = useToastContext();
  const { CSVDownloader, Type } = useCSVDownloader();
  const { LoadingOverlay, showLoading, closeLoading } = useLoading();

  const {
    ModalOverlay,
    showConfirm: modalShowConfirm,
    closeConfirm: modalCloseConfirm,
  } = useModal();

  const [hasActiveFilters, setHasActiveFilters] = useState(false);

  const [state, setState] = useState<State>({
    charges: [],
    hasNextPage: false,
    hasPreviousPage: false,
  });

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<FormFilters>();

  const [ChargesList, { data, loading, error, refetch }] = useLazyQuery(
    CHARGES_QUERY,
    INITIAL_QUERY_STATE_CONFIG
  );

  useEffect(() => {
    ChargesList();
  }, [ChargesList]);

  useEffect(() => {
    if (error) {
      addToast(LIST_ERROR_TOAST);
    }
  }, [addToast, error]);

  const isLoading = loading;

  useEffect(() => {
    if (isLoading) {
      return showLoading();
    }

    if (data) {
      setState({
        charges: data.charges.entries,
        hasNextPage: !!data.charges.afterCursor,
        hasPreviousPage: !!data.charges.beforeCursor,
      });
    }

    closeLoading();
  }, [data, isLoading, closeLoading, showLoading]);

  const onClickContinue = () => {
    modalCloseConfirm();
    addToast(EXPORT_SUCCESS_TOAST);
  };

  const hasActiveFiltersHandler = (value: boolean) => {
    setHasActiveFilters(value);
  };

  const handleClickNext = () => {
    refetch &&
      refetch({
        before: null,
        after: data.charges.afterCursor,
      });
  };

  const handleClickBefore = () => {
    refetch &&
      refetch({
        after: null,
        before: data.charges.beforeCursor,
      });
  };

  const onSubmit: SubmitHandler<FormFilters> = (chargesInput) => {
    const { chargeCreationDate, chargeExpirationDate, chargePaymentDate } =
      chargesInput;

    const isoDatesWithoutTimezone = [
      chargePaymentDate,
      chargeCreationDate,
      chargeExpirationDate,
    ].map((dates) =>
      dates?.map(
        (date) =>
          new Date(date.getTime() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .split('T')[0]
      )
    );

    Object.assign(chargesInput, {
      ...unMaskFormFields(chargesInput),
      chargePaymentDate: isoDatesWithoutTimezone[0],
      chargeCreationDate: isoDatesWithoutTimezone[1],
      chargeExpirationDate: isoDatesWithoutTimezone[2],
    });

    refetch &&
      refetch({
        filters: Object.fromEntries(
          Object.entries(chargesInput).filter(([, value]) => !!value)
        ),
      });
  };

  return (
    <Dashboard
      dashboardMainHeaderTitle={
        <DashboardMainHeaderForm title={DASHBOARD_TITLE}>
          {hasActiveFilters ? (
            <CSVDownloader
              data={[]}
              bom={true}
              type={Type.Link}
              filename="Relatório Financeiro"
              config={{
                delimiter: ';',
              }}
            >
              <Button
                size="sm"
                variant="secondaryGray"
                className="justify-end"
                onClick={() => addToast(EXPORT_SUCCESS_TOAST)}
              >
                <MdCloudDownload size={20} /> Relatório Financeiro
              </Button>
            </CSVDownloader>
          ) : (
            <Button
              size="sm"
              variant="secondaryGray"
              className="justify-end"
              onClick={modalShowConfirm}
            >
              <MdCloudDownload size={20} /> Relatório Financeiro
            </Button>
          )}
        </DashboardMainHeaderForm>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ChargesFormFilters
          errors={errors}
          control={control}
          setValue={setValue}
          register={register}
          isLoading={isLoading}
          hasActiveFilters={hasActiveFiltersHandler}
        />
      </form>

      <div className="rounded-lg">
        <ChargesTable charges={state.charges} />

        <Pagination
          onNextClick={handleClickNext}
          disableNext={!state.hasNextPage}
          onPreviousClick={handleClickBefore}
          disableBefore={!state.hasPreviousPage}
        />
      </div>

      <ChargesAlertModal
        ModalOverlay={ModalOverlay}
        alert={EXPORT_ALERT_MODAL}
        onClickContinue={onClickContinue}
        onClickCloseModal={modalCloseConfirm}
      />

      <LoadingOverlay />
    </Dashboard>
  );
}
