import {
  TransferStatus,
  Transfers as TransfersModel,
} from 'transfers/models/transfers';

import { FinancialAccount } from '__factory__';

export const Transfers = (
  id = new Date().valueOf(),
  transferStatus = TransferStatus.FAILED
): TransfersModel => ({
  id,
  financialAccount: FinancialAccount(id),
  transferStatus,
  transferValue: '2000.50',
  transferDate: new Date().toISOString().split('T')[0],
  transferRequestDate: new Date().toISOString().split('T')[0],
});

export default Transfers;
