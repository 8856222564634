import {
  CooperativeMember as CooperativeMemberModel,
  CooperativeMemberEntityType,
  CooperativeMemberModalityType,
} from 'cooperatives/models/cooperativeMember';

export const CooperativeMember = (
  id = new Date().valueOf(),
  cooperativeMemberEntityType = CooperativeMemberEntityType.COMPANY,
  cooperativeMemberModalityType = CooperativeMemberModalityType.HEADQUARTER
): CooperativeMemberModel => ({
  id,
  cooperativeMemberEntityType,
  cooperativeMemberModalityType,
  cooperativeMemberEmail: `Cooperative member email ${id}`,
  cooperativeMemberDocumentId: `Cooperative Document ${id}`,
  cooperativeMemberLegalName: `Cooperative member legal name ${id}`,
  cooperativeMemberOptionalEmail: [
    `Cooperative member optional email ${id}`,
    `Cooperative member optional email ${id + 1}`,
  ],
  cooperative: {
    id,
    cooperativeLegalName: `joe@example${id}.com`,
  },
});

export default CooperativeMember;
