import { gql } from '@apollo/client';

import { TransfersCounter } from 'transfers/models/transfers';

export interface TransfersCounterList {
  transfers: TransfersCounter;
}

export const transfersTypename = 'TransfersCounter';

export const TRANSFERS_COUNTER_QUERY = gql`
  query transfersCounter {
    transfersCounter {
      transfersTotalAmount
      transfersFailedStatusCount
      transfersAccomplishedStatusCount
    }
  }
`;
