import { gql } from '@apollo/client';

import { DetailedTransfers } from '../models/detailedTransfers';

interface DetailedTransfersConnection {
  afterCursor: string | null;
  beforeCursor: string | null;
  entries: DetailedTransfers[];
}

export interface DetailedTransfersList {
  detailedTransfers: DetailedTransfersConnection;
}

export const detailedTransfersTypename = 'DetailedTransfersConnection';

const MAX_PAGE_SIZE = 100;

export const DETAILED_TRANSFERS_SELECT_QUERY_VARIABLES = {
  variables: { pageSize: MAX_PAGE_SIZE },
};

export const DETAILED_TRANSFERS_QUERY = gql`
  query detailedTransfers(
    $after: String
    $before: String
    $pageSize: Int
    $filters: DetailedTransfersFilterOptions
  ) {
    detailedTransfers(
      after: $after
      before: $before
      pageSize: $pageSize
      filters: $filters
    ) {
      afterCursor
      beforeCursor
      entries {
        id
        charges {
          id
          chargeDate
          chargeStatus
          chargeBillUrl
          chargeEconomyValue
          chargeEconomyValuePercentage
          chargePaymentEndDate
          chargePaymentStartDate
          chargeCreationEndDate
          chargeCreationStartDate
          chargeExpirationEndDate
          chargeExpirationStartDate
          consumerUnit {
            id
            consumerUnitModality
            consumerUnitAddressCity
            consumerUnitAddressState
            consumerUnitAddressStreet
            consumerUnitAddressDistrict
            consumerUnitConsumptionClass
            consumerUnitAddressPostalCode
            consumerUnitAddressComplement
            consumerUnitConsumptionGroupType
            powerDistributionUnitCredentialsUser
            consumerUnitPowerDistributionUnitIdentifier
            cooperativeMember {
              id
              cooperativeMemberLegalName
              cooperativeMemberEntityType
              cooperativeMemberDocumentId
            }
            powerDistributionUnit {
              id
              powerDistributionUnitLegalName
              powerDistributionUnitDeletedAt
              powerDistributionUnitDocumentId
              powerDistributionUnitCountryState
            }
          }
        }
        transfers {
          id
          transferDate
          transferValue
          transferStatus
          transferRequestDate
        }
      }
    }
  }
`;
