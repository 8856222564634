import { ConsumptionGroupsType } from 'consumerUnits/model/consumerUnit';

import {
  ConsumerUnitType,
  ConsumerUnitBillData as ConsumerUnitBillDataModel,
} from 'powerDistributionUnits/powerDistributionUnitBillData/consumerUnitBillData/models/consumerUnitBillData';
import ConsumerUnit from './consumerUnit';

export const ConsumerUnitBillData = (
  id = new Date().valueOf(),
  consumerUnitType = ConsumerUnitType.CONSUMER,
  consumerUnitConsumptionGroupType = ConsumptionGroupsType.A
): ConsumerUnitBillDataModel => ({
  id,
  billConsumerUnitId: id,
  powerDistributionUnitId: id,
  billDueDate: '2022-10-01',
  billIssueDate: '2022-10-01',
  billReferenceDate: '2022-10-01',
  billNextReadingDate: '2022-10-01',
  billReadingStartedAt: '2022-10-01',
  billReadingFinishedAt: '2022-10-01',
  billTotalValue: `${id}`,
  billTotalReadingDays: `${id}`,
  billConsumerUnitType: consumerUnitType,
  billConsumerUnitConsumptionGroupType: consumerUnitConsumptionGroupType,
  billConsumedEnergyCredits: {
    consumedEnergyCreditsTotal: `${id * 30}`,
    consumedEnergyCreditsOnPeak: `${id * 20}`,
    consumedEnergyCreditsOffPeak: `${id * 10}`,
  },
  billConsumedEnergyKwh: {
    consumedEnergyKwhTotal: `${id * 60}`,
    consumedEnergyKwhOnPeak: `${id * 50}`,
    consumedEnergyKwhOffPeak: `${id * 40}`,
  },
  billExcessEnergyCredits: {
    excessEnergyCreditsTotal: `${id * 90}`,
    excessEnergyCreditsOnPeak: `${id * 80}`,
    excessEnergyCreditsOffPeak: `${id * 70}`,
  },
  billGeneratedEnergyCredits: {
    generatedEnergyCreditsTotal: `${id * 120}`,
    generatedEnergyCreditsOnPeak: `${id * 110}`,
    generatedEnergyCreditsOffPeak: `${id * 100}`,
  },
  billConsumedEnergyCreditsTariffs: {
    consumedEnergyCreditsTariffsTotal: `${id * 150}`,
    consumedEnergyCreditsTariffsOnPeak: `${id * 140}`,
    consumedEnergyCreditsTariffsOffPeak: `${id * 130}`,
  },
  consumerUnit: ConsumerUnit(id),
});

export default ConsumerUnitBillData;
