import { useState } from 'react';

import {
  Control,
  useWatch,
  Controller,
  FieldError,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';

import { Button } from 'ui';
import TextField from 'ui/form/TextField';
import CheckboxField from 'ui/form/CheckboxField';
import DatePickerFieldDobleView, {
  RangeDate,
} from 'ui/form/DatePickerFieldDobleView';

import omit from 'lodash/omit';

import { TransferStatus } from 'transfers/models/transfers';

import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

export type FormFilters = {
  financialAccountLegalName: string;
  transferFailedStatus: boolean;
  transferPendingStatus: boolean;
  transferAccomplishedStatus: boolean;
  transferDate?: RangeDate | null;
  transferRequestDate?: RangeDate | null;
};

export type FormErrors = {
  financialAccountLegalName?: FieldError;
  transferFailedStatus?: FieldError;
  transferPendingStatus?: FieldError;
  transferAccomplishedStatus?: FieldError;
  transferDateEndDate?: FieldError;
  transferDateStartDate?: FieldError;
  transferRequestDateEndDate?: FieldError;
  transferRequestDateStartDate?: FieldError;
};

export default function TransfersFormFilters({
  errors,
  control,
  register,
  setValue,
  isLoading,
}: {
  errors: FormErrors;
  isLoading?: boolean;
  control: Control<FormFilters>;
  setValue: UseFormSetValue<FormFilters>;
  register: UseFormRegister<FormFilters>;
}) {
  const TRANSFER_STATUS = [
    {
      label: 'Falha',
      value: TransferStatus.FAILED,
      name: 'transferFailedStatus' as const,
    },
    {
      label: 'Pendente',
      value: TransferStatus.PENDING,
      name: 'transferPendingStatus' as const,
    },
    {
      label: 'Realizado',
      value: TransferStatus.ACCOMPLISHED,
      name: 'transferAccomplishedStatus' as const,
    },
  ];

  const TRANSFER_TEXT_FILTERS = [
    {
      label: 'Conta Financeira',
      placeholder: 'Digitar',
      id: 'financialAccountLegalName' as const,
      error: errors.financialAccountLegalName?.message,
    },
  ];

  const TRANSFER_DATE_FILTERS = [
    {
      id: 1,
      label: 'Solicitação',
      name: 'transferRequestDate' as const,
      error: {
        endDateInput: errors.transferRequestDateEndDate?.message,
        startDateInput: errors.transferRequestDateStartDate?.message,
      },
    },
    {
      id: 2,
      label: 'Transferência',
      name: 'transferDate' as const,
      error: {
        endDateInput: errors.transferDateEndDate?.message,
        startDateInput: errors.transferDateStartDate?.message,
      },
    },
  ];

  const activeFiltersCount = useWatch({
    control,
    name: [
      'financialAccountLegalName',
      'transferFailedStatus',
      'transferPendingStatus',
      'transferAccomplishedStatus',
      'transferDate',
      'transferRequestDate',
    ],
  }).filter((filter) => filter).length;

  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  function handleFiltersOpen() {
    setIsFiltersOpen(!isFiltersOpen);
  }

  function handleClearFilters() {
    TRANSFER_TEXT_FILTERS.forEach((filter) => setValue(filter.id, ''));

    TRANSFER_STATUS.forEach((status) => {
      setValue(status.name, false, {
        shouldValidate: true,
      });
    });

    TRANSFER_DATE_FILTERS.forEach((filter) => {
      setValue(filter.name, undefined, {
        shouldValidate: true,
      });
    });
  }

  return (
    <div className="w-full flex flex-col gap-4 items-start justify-start mb-3">
      <div className="flex flex-col gap-1">
        <p className="font-bold text-gray-dark400">Filtros avançados</p>
        <Button
          size="md"
          type="button"
          variant="advancedFilter"
          onClick={handleFiltersOpen}
          className="flex items-center justify-center"
        >
          <p className="text-sm font-medium">Filtros ({activeFiltersCount})</p>
          {isFiltersOpen ? (
            <MdKeyboardArrowUp size={20} />
          ) : (
            <MdKeyboardArrowDown size={20} />
          )}
        </Button>
      </div>
      {isFiltersOpen && (
        <div className="w-full flex flex-col gap-4 items-start justify-start bg-gray-dark700 rounded-lg">
          <div className="flex gap-12 items-start justify-start p-7">
            <div className="flex flex-col gap-4 items-start justify-start">
              <p className="font-bold text-gray-dark400">Selecionar Entidade</p>
              <div className="flex gap-4 items-start">
                {TRANSFER_TEXT_FILTERS.map((filter) => (
                  <TextField
                    id={filter.id}
                    key={filter.id}
                    error={filter.error}
                    label={filter.label}
                    placeholder={filter.placeholder}
                    {...register(filter.id)}
                  />
                ))}
              </div>
            </div>

            <div className="flex flex-col items-start justify-start">
              <p className="font-bold text-gray-dark400">Status de transferência</p>
              {TRANSFER_STATUS.map((status) => (
                <CheckboxField
                  key={status.value}
                  inputLabel={status.label}
                  {...register(status.name)}
                />
              ))}
            </div>

            <div className="flex flex-col gap-4 items-start justify-start">
              <p className="font-bold text-gray-dark400">Data solicitação</p>
              <div className="flex items-center justify-start gap-4">
                {TRANSFER_DATE_FILTERS.slice(0, 1).map((date) => (
                  <Controller
                    key={date.id}
                    name={date.name}
                    control={control}
                    render={({ field }) => (
                      <DatePickerFieldDobleView
                        {...omit(field, 'ref')}
                        selectRange
                        endLabel="Até"
                        id={date.name}
                        label={date.label}
                        error={date.error}
                        placeholder="DD/MM/AAAA"
                        endPlaceholder="DD/MM/AAAA"
                        calendarWrapperClassName="z-10"
                        rangeInputWrapperClassName="flex items-start justify-center gap-6"
                      />
                    )}
                  />
                ))}
              </div>
            </div>

            <div className="flex flex-col gap-4 items-start justify-start">
              <p className="font-bold text-gray-dark400">Data transferência</p>
              <div className="flex items-center justify-start gap-4">
                {TRANSFER_DATE_FILTERS.slice(1, 2).map((date) => (
                  <Controller
                    key={date.id}
                    name={date.name}
                    control={control}
                    render={({ field }) => (
                      <DatePickerFieldDobleView
                        {...omit(field, 'ref')}
                        selectRange
                        endLabel="Até"
                        id={date.name}
                        label={date.label}
                        error={date.error}
                        placeholder="DD/MM/AAAA"
                        endPlaceholder="DD/MM/AAAA"
                        calendarWrapperClassName="z-10"
                        rangeInputWrapperClassName="flex items-start justify-center gap-6"
                      />
                    )}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="w-full flex items-center justify-between border-t border-gray-dark500 p-7">
            <p className="font-bold flex text-gray-dark500 w-full">
              ({activeFiltersCount}) Filtros selecionados
            </p>
            <div className="w-full flex gap-4 items-center justify-end">
              <Button
                size="sm"
                type="button"
                className="w-24"
                disabled={isLoading}
                variant="secondaryGray"
                onClick={handleClearFilters}
              >
                Limpar
              </Button>
              <Button
                size="sm"
                type="submit"
                disabled={isLoading || !activeFiltersCount}
              >
                {`Aplicar ${activeFiltersCount} filtros`}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
