import { FinancialAccount } from 'financialAccounts/models/financialAccounts';

export type TransferId = number;

export enum TransferStatus {
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  ACCOMPLISHED = 'ACCOMPLISHED',
}

export type TransfersCounter = {
  transfersTotalAmount: string;
  transfersFailedStatusCount: number;
  transfersAccomplishedStatusCount: number;
};

export enum TransferExportType {
  SUMMEDUP = 'SUMMEDUP',
  DETAILED = 'DETAILED',
}

export interface Transfers {
  id: TransferId;
  financialAccount: FinancialAccount;

  transferDate: string;
  transferValue: string;
  transferRequestDate: string;
  transferStatus: TransferStatus;
}
