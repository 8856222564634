import { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';

import { MdCloudDownload } from 'react-icons/md';

import { INITIAL_QUERY_STATE_CONFIG } from 'graphql/apollo/config';

import { Button } from 'ui';
import { ModalProps } from 'ui/models/overlay';
import useToastContext from 'ui/hooks/useToast';
import { useModal } from 'ui/contexts/overlay/Modal';
import { ToastProps } from 'ui/contexts/overlay/Toast';
import { useLoading } from 'ui/contexts/overlay/Loading';

import { Pagination } from 'dashboard/components/table';
import { Page } from 'dashboard/components/dashboard/Breadcrumbs';
import { Dashboard, DashboardMainHeaderForm } from 'dashboard/components/dashboard';

import { DetailedTransfers } from '../models/detailedTransfers';

import { DetailedTransfers as DetailedTransfersData } from '__factory__';

import TransfersExportModal from 'transfers/components/modal/TransfersExportModal';

import DetailedTransfersCounterItem from '../components/counter/DetailedTransfersCounterItem';
import {
  DetailedTransfersCounterList,
  DETAILED_TRANSFERS_COUNTER_QUERY,
} from '../graphql/detailedTransfersCounterQuery';
import DetailedTransfersTable from '../components/table/DetailedTransfersTable';
import { DETAILED_TRANSFERS_QUERY } from '../graphql/detailedTransfersQuerys';

type State = {
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  detailedTransfers: DetailedTransfers[];
};

const LIST_ERROR_TOAST: ToastProps = {
  variant: 'danger',
  title: 'Algo deu errado!',
  text: 'Não foi possível carregar a lista de Detalhes de Transferências',
};

const EXPORT_SUCCESS_TOAST: ToastProps = {
  variant: 'primary',
  title: 'Sucesso!',
  text: 'Sucesso ao baixar Relatório Financeiro',
};

const EXPORT_MODAL: ModalProps = {
  variant: 'primary',
  title: 'Baixar relatório de transferência',
  text: 'Selecione o que você quer baixar:',
};

const DASHBOARD_TITLE = 'Detalhe de transferências';

const TRANSFERS_ROUTE = '/dashboard/billing-cycle/transfers';

const BREADCRUMB_PAGES: Page[] = [
  {
    current: false,
    name: 'Transferências',
    route: TRANSFERS_ROUTE,
  },
  {
    route: null,
    current: true,
    name: DASHBOARD_TITLE,
  },
];

export default function ListDetailedTransfersPage() {
  const { addToast } = useToastContext();
  const { LoadingOverlay, showLoading, closeLoading } = useLoading();

  const {
    ModalOverlay,
    showConfirm: modalShowConfirm,
    closeConfirm: modalCloseConfirm,
  } = useModal();

  const [state, setState] = useState<State>({
    hasNextPage: false,
    detailedTransfers: [],
    hasPreviousPage: false,
  });

  const [DetailedTransfersList, { data, loading, error, refetch }] = useLazyQuery(
    DETAILED_TRANSFERS_QUERY,
    INITIAL_QUERY_STATE_CONFIG
  );

  useEffect(() => {
    DetailedTransfersList();
  }, [DetailedTransfersList]);

  const [
    detailedTransfersCounterSelect,
    {
      data: detailedTransfersCounter,
      loading: loadingTransfersCounter,
      refetch: refetchTransfersCounter,
    },
  ] = useLazyQuery<DetailedTransfersCounterList>(DETAILED_TRANSFERS_COUNTER_QUERY);

  useEffect(() => {
    refetchTransfersCounter && refetchTransfersCounter();

    detailedTransfersCounterSelect();
  }, [detailedTransfersCounterSelect, refetchTransfersCounter]);

  useEffect(() => {
    if (error) {
      addToast(LIST_ERROR_TOAST);
    }
  }, [addToast, error]);

  const isLoading = loading || loadingTransfersCounter;

  useEffect(() => {
    if (isLoading) {
      return showLoading();
    }

    if (data) {
      setState({
        detailedTransfers: data.detailedTransfers.entries,
        hasNextPage: !!data.detailedTransfers.afterCursor,
        hasPreviousPage: !!data.detailedTransfers.beforeCursor,
      });
    }

    /* REFACTOR: FACTORY DATA TO REVIEW APP VISUALIZATION */
    setState({
      detailedTransfers: [DetailedTransfersData(1)],
      hasNextPage: !!data?.detailedTransfers.afterCursor,
      hasPreviousPage: !!data?.detailedTransfers.beforeCursor,
    });

    closeLoading();
  }, [data, isLoading, closeLoading, showLoading]);

  const onClickContinue = () => {
    modalCloseConfirm();
    addToast(EXPORT_SUCCESS_TOAST);
  };

  const handleClickNext = () => {
    refetch &&
      refetch({
        before: null,
        after: data.detailedTransfers.afterCursor,
      });
  };

  const handleClickBefore = () => {
    refetch &&
      refetch({
        after: null,
        before: data.detailedTransfers.beforeCursor,
      });
  };

  return (
    <Dashboard
      dashboardMainHeaderTitle={
        <DashboardMainHeaderForm
          title={DASHBOARD_TITLE}
          breadcrumbPages={BREADCRUMB_PAGES}
        >
          <Button
            size="sm"
            variant="secondaryGray"
            className="justify-end"
            onClick={modalShowConfirm}
          >
            <MdCloudDownload size={20} /> Relatório de Transferência
          </Button>
        </DashboardMainHeaderForm>
      }
    >
      <DetailedTransfersCounterItem
        detailedTransfersCounter={detailedTransfersCounter?.detailedTransfersCounter}
      />

      <div className="rounded-lg">
        <DetailedTransfersTable detailedTransfers={state.detailedTransfers} />

        <Pagination
          onNextClick={handleClickNext}
          disableNext={!state.hasNextPage}
          onPreviousClick={handleClickBefore}
          disableBefore={!state.hasPreviousPage}
        />
      </div>

      <TransfersExportModal
        modal={EXPORT_MODAL}
        ModalOverlay={ModalOverlay}
        onClickContinue={onClickContinue}
        onClickCloseModal={modalCloseConfirm}
      />

      <LoadingOverlay />
    </Dashboard>
  );
}
