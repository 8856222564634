export type CommercialAgreementId = number;

export enum CommercialAgreementReceiptRuleType {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  D20 = 'D20',
  MANUAL = 'MANUAL',
}

export enum CommercialAgreementValidityStatusType {
  ACTUAL = 'ACTUAL',
  EXPIRED = 'EXPIRED',
  FUTURE = 'FUTURE',
}

export interface CommercialAgreement {
  id: CommercialAgreementId;
  commercialAgreementStartValidity: Date;
  commercialAgreementHasPisCofins: boolean;
  commercialAgreementHasExtraRemuneration: boolean;
  commercialAgreementReceiptRule: CommercialAgreementReceiptRuleType;
  commercialAgreementLeaseValue: string;
  commercialAgreementOemValue: string;
  commercialAgreementHasOem: boolean;
  commercialAgreementDiscountMax: string;
  commercialAgreementDiscountMin: string;
  commercialAgreementBaseCostPerCreditIcms: string;
  commercialAgreementBaseCostPerCredit: string;
  commercialAgreementValidityStatus: CommercialAgreementValidityStatusType;
  generationUnit: {
    id: number;
    generationUnitLegalName: string;
  };
}
