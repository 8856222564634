import { useHistory } from 'react-router-dom';

import { TableBodyColumn } from 'ui/components/table';
import TableBodyRow from 'ui/components/table/TableBodyRow';

import { convertToBRL, formatDateToLocaleDateString } from 'utils/form';

import { DetailedTransfers } from 'transfers/detailedTransfers/models/detailedTransfers';

type Props = {
  detailedTransfers: DetailedTransfers;
};

const CHARGES_ROUTE = '/dashboard/billing-cycle/charges';

export default function DetailedTransfersTableItem({
  detailedTransfers: { id, charges, transfers },
}: Props) {
  const { push } = useHistory();

  return (
    <TableBodyRow dataTestId={`detailed-transfers-row-id-${id}`}>
      <TableBodyColumn className="underline cursor-pointer">
        <p
          onClick={() => {
            push({
              pathname: CHARGES_ROUTE,
              state: {
                /* REFACTOR: WE NEED TO INCLUDE THE RIGHT PROPS HERE */
              },
            });
          }}
        >
          {charges.id}
        </p>
      </TableBodyColumn>
      <TableBodyColumn>
        {charges.consumerUnit.consumerUnitPowerDistributionUnitIdentifier}
      </TableBodyColumn>
      <TableBodyColumn>
        {charges.consumerUnit.cooperativeMember.cooperativeMemberLegalName}
      </TableBodyColumn>
      <TableBodyColumn>
        {/* REFACTOR: FEES ↓*/}
        {convertToBRL('35')}
      </TableBodyColumn>
      <TableBodyColumn>{convertToBRL(transfers.transferValue)}</TableBodyColumn>
      {/* REFACTOR: FINES ↓*/}
      <TableBodyColumn>{convertToBRL('80')}</TableBodyColumn>
      <TableBodyColumn>
        {formatDateToLocaleDateString(charges.chargePaymentDate)}
      </TableBodyColumn>
    </TableBodyRow>
  );
}
