import { DetailedTransfers as DetailedTransfersModel } from 'transfers/detailedTransfers/models/detailedTransfers';

import { Charges, Transfers } from '__factory__';

export const DetailedTransfers = (
  id = new Date().valueOf()
): DetailedTransfersModel => ({
  id: id,
  charges: Charges(id),
  transfers: Transfers(id),
});

export default DetailedTransfers;
