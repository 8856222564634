import { gql } from '@apollo/client';

import { Transfers } from 'transfers/models/transfers';

interface TransfersConnection {
  entries: Transfers[];
  afterCursor: string | null;
  beforeCursor: string | null;
}

export interface TransfersList {
  transfers: TransfersConnection;
}

export const transfersTypename = 'TransfersConnection';

const MAX_PAGE_SIZE = 100;

export const TRANSFERS_SELECT_QUERY_VARIABLES = {
  variables: { pageSize: MAX_PAGE_SIZE },
};

export const TRANSFERS_QUERY = gql`
  query transfers(
    $after: String
    $before: String
    $pageSize: Int
    $filters: TransfersFilterOptions
  ) {
    transfers(
      after: $after
      before: $before
      pageSize: $pageSize
      filters: $filters
    ) {
      afterCursor
      beforeCursor
      entries {
        transfers {
          id
          transferDate
          transferValue
          transferStatus
          transferRequestDate

          financialAccount {
            id
            financialAccountLegalName
            financialAccountEntityType
            financialAccountDocumentId
            financialAccountAddressState
            financialAccountAddressPostalCode
          }
        }
      }
    }
  }
`;
