import { gql } from '@apollo/client';

import { DetailedTransfersCounter } from '../models/detailedTransfers';

export interface DetailedTransfersCounterList {
  detailedTransfersCounter: DetailedTransfersCounter;
}

export const detailedTransfersTypename = 'DetailedTransfersCounter';

export const DETAILED_TRANSFERS_COUNTER_QUERY = gql`
  query detailedTransfersCounter {
    detailedTransfersCounter {
      chargesCount
      transfersFeesValue
      transfersFinesValue
      transfersTotalAmount
      transfersOriginalValue
      transfersAccomplishedStatusCount
    }
  }
`;
