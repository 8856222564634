import Table from 'ui/components/table/Table';
import TableBody from 'ui/components/table/TableBody';
import TableHead from 'ui/components/table/TableHead';
import TableHeadColumn from 'ui/components/table/TableHeadColumn';

import { Transfers } from 'transfers/models/transfers';

import TransfersTableItem from './TransfersTableItem';

type Props = {
  transfers: Transfers[];
};

export default function TransfersTable({ transfers }: Props) {
  return (
    <Table>
      <TableHead>
        <TableHeadColumn>ID</TableHeadColumn>
        <TableHeadColumn>CONTA FINANCEIRA</TableHeadColumn>
        <TableHeadColumn>DATA SOLICITAÇÃO</TableHeadColumn>
        <TableHeadColumn>DATA TRANSFERÊNCIA</TableHeadColumn>
        <TableHeadColumn>VALOR TRANSFERÊNCIA</TableHeadColumn>
        <TableHeadColumn>STATUS</TableHeadColumn>
        <TableHeadColumn className="text-center">DETALHES</TableHeadColumn>
      </TableHead>
      <TableBody>
        {transfers.map((transfer: Transfers) => (
          <TransfersTableItem key={transfer.id} transfers={transfer} />
        ))}
      </TableBody>
    </Table>
  );
}
