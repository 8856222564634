import { EconomyReport as EconomyReportModel } from 'economyReport/model/economyReport';
import { EconomyReportData as EconomyReportDataModel } from 'economyReport/model/economyReport';

import ConsumerUnit from './consumerUnit';
import GenerationUnit from './generationUnit';
import CooperativeMember from './cooperativeMember';
import TariffFlagModality from './tariffFlagModality';
import ConsumerUnitBillData from './consumerUnitBillData';
import { ApportionmentCycleConsumerUnit } from './apportionmentCycleConsumerUnit';

export const EconomyReport = (id = new Date().valueOf()): EconomyReportModel => ({
  economyReportExternalId: id,
  flagEconomy: `${id + 100}`,
  economyValue: `${id + 100}`,
  energyEconomy: `${id + 100}`,
  economyReferenceDate: `${id + 2020}`,
  economyReportUrl: 'https://www.nexenergy.com',
  consumerUnit: ConsumerUnit(id),
  cooperativeMember: CooperativeMember(id),
});

export const EconomyReportData = (
  id = new Date().valueOf()
): EconomyReportDataModel => ({
  id: id,
  usedCredits: `${id + 100}`,
  flagEconomy: `${id + 100}`,
  consumerUnitId: `${id + 1}`,
  chargeDueDate: '2023-01-01',
  energyEconomy: `${id + 100}`,
  receivedCredits: `${id + 100}`,
  economyValue: `${id + 1485.15}`,
  usedCreditsOnPeak: `${id + 123}`,
  accumulatedCredits: `${id + 543}`,
  usedCreditsOffPeak: `${id + 129}`,
  chargeTotalCharged: `${id + 8820.3}`,
  receivedCreditsOnPeak: `${id + 784}`,
  receivedCreditsOffPeak: `${id + 555}`,
  accumulatedCreditsOnPeak: `${id + 487}`,
  accumulatedCreditsOffPeak: `${id + 255}`,
  approximateChargeAmount: `${id + 33400}`,
  billTotalValue: `${id + 3250.45}`,
  economyReports: [
    EconomyReport(id),
    EconomyReport(id + 1),
    EconomyReport(id + 2),
    EconomyReport(id + 3),
    EconomyReport(id + 4),
    EconomyReport(id + 5),
  ],
  economyReportUrl: 'https://www.nexenergy.com',
  consumerUnitConsumptionGroupType:
    ConsumerUnit(id).consumerUnitConsumptionGroupType,
  billReferenceDate: ConsumerUnitBillData(id).billReferenceDate,
  consumerUnitAddressCity: ConsumerUnit(id).consumerUnitAddressCity,
  consumerUnitAddressState: ConsumerUnit(id).consumerUnitAddressState,
  consumerUnitAddressStreet: ConsumerUnit(id).consumerUnitAddressStreet,
  billTotalValueAddChargeTotalCharged: `${id + 3250.45 + 8820.3}`,
  apportionmentCycleConsumerUnitPercentual: ApportionmentCycleConsumerUnit(id)
    .apportionmentCycleConsumerUnitPercentual as unknown as string,
  consumerUnitPowerDistributionUnitIdentifier:
    ConsumerUnit(id).consumerUnitPowerDistributionUnitIdentifier,
  generationUnitLegalName: GenerationUnit(id).generationUnitLegalName,
  generationUnitAddressCity: GenerationUnit(id).generationUnitAddressCity,
  generationUnitAddressState: GenerationUnit(id).generationUnitAddressState,
  generationUnitAddressStreet: GenerationUnit(id).generationUnitAddressStreet,
  cooperativeMemberLegalName: CooperativeMember(id).cooperativeMemberLegalName,
  cooperativeMemberDocumentId: CooperativeMember(id).cooperativeMemberDocumentId,
  generationUnitPowerCapacity: GenerationUnit(id)
    .generationUnitPowerCapacity as unknown as string,
  tariffFlagModalityModalityFlag:
    TariffFlagModality(id).tariffFlagModalityModalityFlag,
  generationUnitConsumptionGroupType:
    GenerationUnit(id).generationUnitConsumptionGroupType,
});

export default EconomyReport;
